import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";

const DrivePlayer = () => {
	const { driveId } = useParams();

	const url = `https://drive.google.com/file/d/${driveId}/preview`;

	const [showModal, setShowModal] = useState(false);

	// 1. Dynamically load the HubSpot Meetings script once
	useEffect(() => {
		const script = document.createElement("script");
		script.src =
			"https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js";
		script.async = true;
		document.body.appendChild(script);

		return () => {
			document.body.removeChild(script);
		};
	}, []);

	useEffect(() => {
		if (showModal) {
		  const script = document.createElement("script");
		  script.src = "https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js";
		  script.async = true;
		  document.body.appendChild(script);
	  
		  return () => {
			// remove if you want
			document.body.removeChild(script);
		  };
		}
	  }, [showModal]);
	  

	const handleOpenModal = () => setShowModal(true);
	const handleCloseModal = () => setShowModal(false);

	return (
		<div
			style={{
				minHeight: "100vh",
				backgroundColor: "#1c004b",
				margin: 0,
				padding: 0,
			}}
		>
			<div
				className="container"
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
					padding: "20px 20px",
				}}
			>
				<a href="https://ratava.com">
					<img
						src="/logo-white.png"
						alt="logo"
						style={{
							width: "50%",
							padding: "20px 0",
						}}
					/>
				</a>
				<button
					style={{
						backgroundColor: "#ffffff",
						border: "none",
						color: "#1c004b",
						padding: "10px 20px",
						fontSize: "16px",
						cursor: "pointer",
						borderRadius: "4px",
					}}
					onClick={() => {
						window.location.href = "https://ratava.com/";
					}}
				>
					Learn More
				</button>
			</div>

			<div
				style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					color: "white",
					position: "relative",
					width: "80%",
					maxWidth: "900px",
					aspectRatio: "16/9",
					margin: "auto",
				}}
			>
				<iframe
					src={url}
					allow="encrypted-media"
					allowFullScreen
					title="Video Message"
					style={{
						border: "none",
						position: "absolute",
						top: 0,
						left: 0,
						width: "100%",
						height: "100%",
					}}
				></iframe>
			</div>
			<div style={{ textAlign: "center", marginTop: "1rem" }}>
				<Button style={{
					backgroundColor: "#ffffff",
					border: "none",
					color: "#1c004b",
					padding: "10px 20px",
					fontSize: "16px",
					cursor: "pointer",
					borderRadius: "4px",
				}}onClick={handleOpenModal} variant="primary">
					Schedule a Meeting
				</Button>
			</div>

			{/* 3. React Bootstrap Modal with HubSpot container */}
			<Modal show={showModal} onHide={handleCloseModal} size="lg" centered>
				<Modal.Header closeButton>
					<Modal.Title>Book a Meeting</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{/* The container that HubSpot transforms into the scheduling iframe */}
					<div
						className="meetings-iframe-container"
						data-src="https://meetings.hubspot.com/mitchell173?embed=true"
						style={{ minHeight: "600px" }}
					/>
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default DrivePlayer;
