import { useState, useEffect, useCallback, useContext } from 'react';
import { useHttpClient } from './http-hook';
import { AuthContext } from '../context/auth-context';
const useAudios = () => {
    const [audios, setAudios] = useState([]);
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const auth = useContext(AuthContext);
    const fetchAudios = useCallback(async () => {
        try {
            console.log('Fetching audios...'); // Debugging line
            const response = await sendRequest(
                '/api/audios',
                'GET',
                null,
                {
                    Authorization: 'Bearer ' + auth.token
                }
            );
            console.log('Audios fetched:', response.data); // Debugging line
            setAudios(response.data);

        } catch (err) {
            console.error('Error fetching audios:', err);
        }
    }, [sendRequest, auth.token]);

    const uploadAudio = useCallback(async (audioFile, title, orgId) => {
        try {
            const formData = new FormData();
            formData.append('audio', audioFile);
            formData.append('orgId', orgId);
            formData.append('title', title);
            
            const response = await sendRequest(
                '/api/audio',
                'POST',
                formData,
                {
                    Authorization: 'Bearer ' + auth.token
                },
                true // This indicates it's a FormData request
            );
            // Refresh the audio list after upload
            await fetchAudios();
            return response.data;
        } catch (err) {
            console.error('Error uploading audio:', err);
            throw err;
        }
    }, [sendRequest, auth.token, fetchAudios]);

    const getAudioById = useCallback(async (audioId) => {
        try {
            const response = await sendRequest(
                `/api/audio/${audioId}`,
                'GET',
                null,
                {
                    Authorization: 'Bearer ' + auth.token
                }
            );
            return response.data;
        } catch (err) {
            console.error('Error fetching audio by ID:', err);
            return null;
        }
    }, [sendRequest, auth.token]);

    useEffect(() => {
        fetchAudios();
    }, [fetchAudios]);

    return {
        audios,
        isLoading,
        error,
        clearError,
        fetchAudios,
        uploadAudio,
        getAudioById
    };
};
export default useAudios;