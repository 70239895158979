import React, { useState, useEffect, useContext } from "react";
import {
	Button,
	Card,
	Spinner,
	Tooltip,
	OverlayTrigger,
    Form,
} from "react-bootstrap";
import useAudios from "../../shared/hooks/useAudios";
import ToastError from "../../shared/elements/ToastError";
import useAudioRecorder from "../../shared/hooks/useAudioRecorder";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import { FaStopCircle, FaMicrophone } from "react-icons/fa";
import DragAndDropAudioUpload from "../components/DragAndDropAudioUpload";

const Audios = () => {
	const authContext = useContext(AuthContext);
	const { sendRequest } = useHttpClient();
	const [userOrganizations, setUserOrganizations] = useState([]);
	const [selectedOrgId, setSelectedOrgId] = useState("");
	const [showOrganizationDropdown, setShowOrganizationDropdown] =
		useState(false);
	const [organizationsLoaded, setOrganizationsLoaded] = useState(false);
	const { audios, isLoading, error, clearError, uploadAudio } = useAudios();
	const {
		isRecording,
		audioBlob,
		audioURL,
		startRecording,
		stopRecording,
		resetRecording,
	} = useAudioRecorder();
	const [selectedFile, setSelectedFile] = useState(null);
	const [recordings, setRecordings] = useState([]);
	const [currentlyPlaying, setCurrentlyPlaying] = useState(null);

	useEffect(() => {
		if (audioURL) {
			setRecordings((prev) => [
				...prev,
				{
					url: audioURL,
					blob: audioBlob,
					title: `Recording ${prev.length + 1}`,
				},
			]);
		}
	}, [audioURL, audioBlob]);

	const handleChangeRecordingTitle = (index, newTitle) => {
		setRecordings((prev) => {
			const updated = [...prev];
			updated[index].title = newTitle;
			return updated;
		});
	};

	const handleSaveRecording = async (index) => {
		const recording = recordings[index];
		if (!recording || !recording.blob) return;

		try {
			await uploadAudio(recording.blob, recording.title, selectedOrgId);
			setRecordings((prev) => prev.filter((_, i) => i !== index));
		} catch (err) {
			console.error("Upload failed:", err);
		}
	};

	const handleUpload = async (file, fileTitle) => {
		if (!file) return;
		try {
			await uploadAudio(file, fileTitle, selectedOrgId);
			setSelectedFile(null);
		} catch (err) {
			console.error("Upload failed:", err);
		}
	};

	const togglePlayPause = (audioUrl) => {
		if (currentlyPlaying === audioUrl) {
			setCurrentlyPlaying(null);
		} else {
			setCurrentlyPlaying(audioUrl);
		}
	};

	const deleteRecording = (index) => {
		setRecordings((prev) => prev.filter((_, i) => i !== index));
	};

	const formatDate = (timestamp) => {
		if (!timestamp) return "No date available";

		// Handle Firestore Timestamp object with _seconds
		if (timestamp && timestamp._seconds) {
			const date = new Date(timestamp._seconds * 1000);
			const month = date.toLocaleString("en-US", { month: "short" });
			const day = date.getDate();
			const time = date.toLocaleString("en-US", {
				hour: "numeric",
				minute: "2-digit",
				hour12: true,
			});

			return `${month} ${day} at ${time}`;
		}

		return "Invalid Date";
	};

	useEffect(() => {
		const fetchUserOrganizations = async () => {
			try {
				let organizations = [];
				if (authContext.isSuperAdmin) {
					const response = await sendRequest(
						"/api/organizations",
						"GET",
						null,
						{
							Authorization: "Bearer " + authContext.token,
						}
					);
					organizations = response.data;
				} else {
					// Regular users get memberships from authContext
					organizations = authContext.memberships;
				}

				setUserOrganizations(organizations);
				setShowOrganizationDropdown(
					authContext.isSuperAdmin || organizations.length > 1
				);
				setOrganizationsLoaded(true);
			} catch (err) {
				console.error("Error fetching organizations:", err);
			}
		};

		fetchUserOrganizations();
	}, [authContext, sendRequest]);

	useEffect(() => {
		if (organizationsLoaded && userOrganizations.length === 1) {
            console.log(userOrganizations);
			setSelectedOrgId(userOrganizations[0].orgId);
		}
	}, [organizationsLoaded, userOrganizations]);

	return (
		<div className="container mx-auto p-4">
			<div style={{ padding: "0.25rem .5rem" }}>
				<OverlayTrigger
					placement="bottom" // Choose tooltip placement: top, right, bottom, left
					overlay={
						<Tooltip id="beta-tooltip">
							This feature is new! Please let us know if you have any issues.
						</Tooltip>
					}
				>
					<span className="beta-badge ms-3">Beta</span>
				</OverlayTrigger>
			</div>
			{showOrganizationDropdown && (
				<Card className="mb-3">
					<Card.Header>
						<Card.Title>Select Organization</Card.Title>
					</Card.Header>
					<Card.Body>
						<Form.Group controlId="orgId">
							<Form.Label>Organization</Form.Label>
							<Form.Control
								as="select"
								value={selectedOrgId}
								onChange={(e) => setSelectedOrgId(e.target.value)}
							>
								<option value="">Select an organization</option>
								{userOrganizations.map((org) => (
									<option key={org.orgId} value={org.orgId}>
										{org.orgName || org.name}
									</option>
								))}
							</Form.Control>
						</Form.Group>
					</Card.Body>
				</Card>
			)}
			<ToastError show={!!error} message={error} onClose={clearError} />
			<div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
				{/* Recording Card */}
				<Card>
					<Card.Header>
						<Card.Title>Record Audio</Card.Title>
					</Card.Header>
					<Card.Body>
						<div className="d-flex align-items-center">
							{!audioURL && (
								<>
									{isRecording ? (
										<Button
											variant="danger"
											onClick={stopRecording}
											className="d-flex align-items-center"
										>
											<FaStopCircle size={20} className="me-2" />
											Stop
										</Button>
									) : (
										<Button
											variant="primary"
											onClick={startRecording}
											className="d-flex align-items-center"
										>
											<FaMicrophone size={20} className="me-2" />
											Record
										</Button>
									)}
									{isRecording && (
										<div className="d-flex align-items-center ms-3">
											<div className="recording-dot me-2"></div>
											<span className="text-danger fw-bold">Recording...</span>
										</div>
									)}
								</>
							)}

							{audioURL && (
								<div className="d-flex align-items-center">
									<audio
										controls
										className="me-3"
										style={{ maxWidth: "400px" }}
									>
										<source src={audioURL} type="audio/mp3" />
										Your browser does not support the audio element.
									</audio>
									<Button variant="warning" onClick={resetRecording}>
										Re-record
									</Button>
								</div>
							)}
						</div>
						<div className="d-flex flex-column gap-2">
							{recordings.map((recording, index) => (
								<div key={index} className="d-flex align-items-center gap-2">
									<Button
										variant="outline-primary"
										onClick={() => togglePlayPause(recording.url)}
									>
										{currentlyPlaying === recording.url ? "Pause" : "Play"}
									</Button>
									<input
										type="text"
										value={recording.title}
										onChange={(e) =>
											handleChangeRecordingTitle(index, e.target.value)
										}
										style={{ width: "200px", outline: "1px solid #ccc" }}
									/>
									<Button
										variant="success"
										onClick={() => handleSaveRecording(index)}
									>
										Save
									</Button>
									<Button
										variant="outline-danger"
										onClick={() => deleteRecording(index)}
									>
										Delete
									</Button>
									{currentlyPlaying === recording.url && (
										<audio
											src={recording.url}
											autoPlay
											onEnded={() => setCurrentlyPlaying(null)}
										/>
									)}
								</div>
							))}
						</div>
					</Card.Body>
				</Card>
				<DragAndDropAudioUpload
					selectedFile={selectedFile}
					setSelectedFile={setSelectedFile}
					handleUpload={handleUpload}
				/>
			</div>
			{/* Saved Audios Card */}
			<Card>
				<Card.Header>
					<Card.Title>Saved Audio Files</Card.Title>
				</Card.Header>
				<Card.Body>
					{isLoading ? (
						<Spinner animation="border" />
					) : (
						<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3">
							{audios.map((audio) => (
								<Card key={audio.id}>
									<Card.Body>
										<audio controls src={audio.url} className="w-100" />
										<div className="mt-2">{audio.title}</div>
										<div className="mt-2 text-muted">
											Created: {formatDate(audio.createdAt)}
										</div>
									</Card.Body>
								</Card>
							))}
						</div>
					)}
				</Card.Body>
			</Card>
		</div>
	);
};
export default Audios;
