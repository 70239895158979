import React, { useState, useContext } from "react";
import {
	Modal,
	Button,
	Toast,
	Form,
	OverlayTrigger,
	Tooltip,
	Dropdown,
} from "react-bootstrap";
import ReactPlayer from "react-player";
import { AuthContext } from "../../shared/context/auth-context";
import { useHttpClient } from "../../shared/hooks/http-hook";
import useFolders from "../../shared/hooks/useFolders";

const VideoModal = ({
	show,
	onHide,
	video,
	onDelete,
	renderStatus,
	isLoading,
}) => {
	const [showToast, setShowToast] = useState(false);
	const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
	const [showFolderDropdown, setShowFolderDropdown] = useState(false);
	const [toastMessage, setToastMessage] = useState("");
	const authContext = useContext(AuthContext);
	const { sendRequest } = useHttpClient();
	const { folders } = useFolders();

	const handleToggleShared = async () => {
		if (!video) return;
		console.log("video share toggle");
		try {
			const response = await sendRequest(
				`/api/videos/${video.id}/toggle-shared`,
				"POST",
				null,
				{
					Authorization: "Bearer " + authContext.token,
					"Content-Type": "application/json",
				}
			);
			const updatedSharedStatus = response.data.shared;
			video.shared = updatedSharedStatus;
		} catch (err) {
			console.error("Error toggling video shared status:", err);
		}
	};

	const handleToggleVideo = async () => {
		if (!video) return;
		try {
			const response = await sendRequest(
				`/api/videos/${video.id}/toggle-public`,
				"POST",
				null,
				{
					Authorization: "Bearer " + authContext.token,
					"Content-Type": "application/json",
				}
			);
			const updatedPublicStatus = response.data.public;
			video.public = updatedPublicStatus;
		} catch (err) {
			console.error("Error finalizing video:", err);
		}
	};

	const handleDeleteClick = () => {
		if (showDeleteConfirm) {
			onDelete();
		} else {
			setShowDeleteConfirm(true);
			setTimeout(() => {
				setShowDeleteConfirm(false);
			}, 3000);
		}
	};

	const handleAddToFolder = async (folderId) => {
		if (!video || !folderId) return;
		try {
			await sendRequest(
				`/api/folders/${folderId}/files`,
				"POST",
				JSON.stringify({
					name: video.title,
					size: 0,
					type: "video",
					url: video.video_url,
					thumbnail_url: video.thumbnail_url,
					videoId: video.id,
				}),
				{
					Authorization: "Bearer " + authContext.token,
					"Content-Type": "application/json",
				}
			);
			setToastMessage("Video added to folder successfully!");
			setShowToast(true);
			setShowFolderDropdown(false);
		} catch (err) {
			console.error("Error adding video to folder:", err);
			setToastMessage(err?.message || "Error adding video to folder");
			setShowToast(true);
		}
	};

	return (
		<>
			<Modal
				show={show}
				onHide={onHide}
				size="lg"
				style={{ position: "fixed" }}
				dialogClassName="modal-fixed"
			>
				<style>
					{`
                        .modal-fixed {
                            position: fixed !important;
                            top: 50% !important;
                            left: 50% !important;
                            transform: translate(-50%, -50%) !important;
                            margin: 0 !important;
                        }
                        .modal-fixed .modal-content {
                            max-height: calc(100vh - 60px);
                            overflow-y: auto;
                        }
                            .folder-dropdown-menu {
                            position: absolute !important;
                            width: 100% !important;
                            max-height: 200px !important;
                            overflow-y: auto !important;
                            bottom: calc(100% + 16px) !important;
                            left: 0 !important;
                            transform: none !important;
                            border-radius: 4px;
                            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
                            z-index: 1000;
                        }
                        .folder-dropdown-menu::-webkit-scrollbar {
                            width: 8px;
                        }
                        .folder-dropdown-menu::-webkit-scrollbar-track {
                            background: transparent;
                        }
                        .folder-dropdown-menu::-webkit-scrollbar-thumb {
                            background-color: #E5E7EB;
                            border-radius: 20px;
                            border: 2px solid white;
                        }
                        .folder-dropdown-menu::-webkit-scrollbar-thumb:hover {
                            background-color: #D1D5DB;
                        }
                        .folder-dropdown-menu .dropdown-item {
                            padding: 0.5rem 1rem;
                            cursor: pointer;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            color: #000000;
                        }
                        .folder-dropdown-menu .dropdown-item:hover {
                            background-color: #f3f4f6;
                        }
                        .pinned-label {
                            color: #3B82F6;
                            font-size: 0.75rem;
                            font-weight: 500;
                            margin-left: 8px;
                        }
                    `}
				</style>
				<Modal.Header closeButton style={{ padding: "0.75rem 1rem" }}>
					<Modal.Title>{video ? video.title : "Video Details"}</Modal.Title>
				</Modal.Header>
				<Modal.Body style={{ padding: "1rem" }}>
					{isLoading ? (
						<div className="d-flex flex-column" style={{ width: "100%" }}>
							{/* Video player skeleton */}
							<div
								className="bg-gray-200 rounded-md animate-pulse"
								style={{
									width: "100%",
									paddingBottom: "56.25%",
									position: "relative",
								}}
							/>

							{/* Content skeleton */}
							<div className="mt-3">
								{/* Status skeleton */}
								<div className="d-flex align-items-center mb-3">
									<div className="bg-gray-200 rounded-full h-2 w-2 me-2 animate-pulse" />
									<div className="bg-gray-200 rounded h-4 w-20 animate-pulse" />
								</div>

								{/* Script section skeleton */}
								<div className="mb-3">
									<div className="bg-gray-200 rounded h-4 w-16 mb-2 animate-pulse" />
									<div
										className="bg-gray-100 rounded animate-pulse"
										style={{
											height: "60px",
											border: "1px solid #dee2e6",
										}}
									/>
								</div>

								{/* Avatar info skeleton */}
								<div className="mb-2 d-flex align-items-center">
									<div className="bg-gray-200 rounded h-4 w-32 animate-pulse" />
								</div>

								{/* Created by skeleton */}
								<div className="mb-3 d-flex align-items-center">
									<div className="bg-gray-200 rounded h-4 w-32 animate-pulse" />
								</div>

								{/* Form controls skeleton */}
								<div className="mb-3">
									<div className="d-flex justify-content-between align-items-center mb-2">
										<div className="bg-gray-200 rounded h-4 w-48 animate-pulse" />
										<div className="bg-gray-200 rounded h-8 w-28 animate-pulse" />
									</div>

									<div className="d-flex justify-content-between align-items-center">
										<div className="bg-gray-200 rounded h-4 w-48 animate-pulse" />
										<div className="bg-gray-200 rounded h-8 w-28 animate-pulse" />
									</div>
								</div>

								{/* Action buttons skeleton */}
								<div className="d-flex justify-content-center gap-2">
									<div className="bg-gray-200 rounded h-8 w-24 animate-pulse" />
									<div className="bg-gray-200 rounded h-8 w-24 animate-pulse" />
								</div>
							</div>
						</div>
					) : (
						video && (
							<div className="d-flex flex-column h-100">
								{video.video_url ? (
									<ReactPlayer
										url={video.video_url}
										controls={true}
										width="100%"
									/>
								) : (
									<img
										src={video.thumbnail_url}
										width="100%"
										alt={video.avatar?.avatar_name}
									/>
								)}
								<div className="mt-3 position-relative">
									{renderStatus(video.status, video.video_url, video.public)}
									{!isLoading && (
										<>
											<h5 className="mb-2">Script:</h5>
											<div
												className="p-2 bg-light rounded mb-3"
												style={{
													maxHeight: "150px",
													overflowY: "auto",
													border: "1px solid #dee2e6",
													fontSize: "0.9rem",
												}}
											>
												{video.script?.content || "No script available"}
											</div>

											<div className="mb-2" style={{ fontSize: "0.9rem" }}>
												<strong>Avatar: </strong>
												{video.avatar?.avatar_name || "N/A"}
											</div>

											<div className="mb-3" style={{ fontSize: "0.9rem" }}>
												<strong>Created By: </strong>
												{video.createdBy || "Unknown"}
											</div>

											{(authContext.isSuperAdmin ||
												authContext.orgRole === "orgAdmin" ||
												video.userId === authContext.userId) && (
												<Form>
													<Form.Group className="mb-2">
														<div className="d-flex justify-content-between align-items-center">
															<OverlayTrigger
																placement="top"
																delay={{ show: 250, hide: 400 }}
																overlay={
																	<Tooltip>
																		{video.shared
																			? "Make your video private"
																			: "Share your video with your organization!"}
																	</Tooltip>
																}
															>
																<Form.Check
																	type="switch"
																	id="share-switch"
																	label="Share internally with organization"
																	checked={video.shared}
																	onChange={handleToggleShared}
																	style={{ fontSize: "0.9rem" }}
																/>
															</OverlayTrigger>
															{video.shared && (
																<OverlayTrigger
																	placement="top"
																	delay={{ show: 150, hide: 200 }}
																	overlay={
																		<Tooltip>
																			Share video link with organization
																		</Tooltip>
																	}
																>
																	<Button
																		variant="primary"
																		size="sm"
																		onClick={() => {
																			navigator.clipboard.writeText(
																				`${window.location.origin}/video?id=${video.id}`
																			);
																			setShowToast(true);
																		}}
																	>
																		Share Internal Link
																	</Button>
																</OverlayTrigger>
															)}
														</div>
													</Form.Group>

													<Form.Group className="mb-3">
														<div className="d-flex justify-content-between align-items-center">
															<OverlayTrigger
																placement="top"
																delay={{ show: 250, hide: 400 }}
																overlay={
																	<Tooltip>
																		{video.public
																			? "Make your video private"
																			: "Share your video publicly!"}
																	</Tooltip>
																}
															>
																<Form.Check
																	type="switch"
																	id="share-switch"
																	label="Share public video link"
																	checked={video.public}
																	onChange={handleToggleVideo}
																	style={{ fontSize: "0.9rem" }}
																/>
															</OverlayTrigger>
															{video.public && (
																<div>
																	<OverlayTrigger
																		placement="top"
																		delay={{ show: 150, hide: 200 }}
																		overlay={
																			<Tooltip>
																				Share video link with public
																			</Tooltip>
																		}
																	>
																		<Button
																			variant="primary"
																			size="sm"
																			onClick={() => {
																				navigator.clipboard.writeText(
																					`${window.location.origin}/share/${video.id}`
																				);
																				setShowToast(true);
																			}}
																		>
																			Share Public Link
																		</Button>
																	</OverlayTrigger>
																</div>
															)}
														</div>
													</Form.Group>
													{video.public && (
														<Form.Group className="mb-3">
															<div
																className="p-2 bg-light rounded mb-3"
																style={{
																	maxHeight: "150px",
																	overflowY: "auto",
																	border: "1px solid #dee2e6",
																	fontSize: "0.9rem",
																}}
															>
																{`${window.location.origin}/share/${video.id}`}
															</div>
														</Form.Group>
													)}
												</Form>
											)}

											<Dropdown className="mb-3">
												<Dropdown.Menu
													show={showFolderDropdown}
													className="folder-dropdown-menu"
												>
													{folders.length === 0 ? (
														<Dropdown.Item disabled>
															No folders available
														</Dropdown.Item>
													) : (
														folders.map((folder) => (
															<Dropdown.Item
																key={folder.id}
																onClick={() => handleAddToFolder(folder.id)}
															>
																{folder.name}
																{folder.isPinned && (
																	<span className="pinned-label">Pinned</span>
																)}
															</Dropdown.Item>
														))
													)}
												</Dropdown.Menu>
											</Dropdown>
											<div className="d-flex justify-content-center gap-2">
												{(authContext.isSuperAdmin ||
													authContext.orgRole === "orgAdmin" ||
													video.userId === authContext.userId) && (
													<Button
														variant="danger"
														onClick={handleDeleteClick}
														size="sm"
													>
														{showDeleteConfirm
															? "Click again to confirm delete"
															: "Delete Video"}
													</Button>
												)}
												<Button
													variant="primary"
													size="sm"
													onClick={() =>
														setShowFolderDropdown(!showFolderDropdown)
													}
												>
													Add to Folder
												</Button>
											</div>
										</>
									)}
								</div>
							</div>
						)
					)}
				</Modal.Body>
			</Modal>

			<Toast
				onClose={() => setShowToast(false)}
				show={showToast}
				delay={6000}
				autohide
				style={{ position: "fixed", top: 20, right: 20, zIndex: 9999 }}
			>
				<Toast.Header>{toastMessage}</Toast.Header>
			</Toast>
		</>
	);
};

export default VideoModal;
