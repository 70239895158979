import { MoreVertical, MoreHorizontal, Folder, /*Music,  FileText, Image,*/ Plus } from "lucide-react"
import { useState, useContext, useEffect } from 'react'
import { AuthContext } from '../../shared/context/auth-context'
import { useHttpClient } from '../../shared/hooks/http-hook'
import useFolders from '../../shared/hooks/useFolders'
import { useNavigate } from 'react-router-dom'

export default function Folders() {
    const [showModal, setShowModal] = useState(false)
    const [newFolderName, setNewFolderName] = useState('')
    const [error, setError] = useState('')
    const auth = useContext(AuthContext)
    const { sendRequest } = useHttpClient()
    const [userOrganizations, setUserOrganizations] = useState([])
    const [selectedOrgId, setSelectedOrgId] = useState('')
    const [showOrganizationDropdown, setShowOrganizationDropdown] = useState(false)
    const [organizationsLoaded, setOrganizationsLoaded] = useState(false)
    const { folders, isLoading, createFolder, deleteFolder, togglePinFolder } = useFolders()
    const navigate = useNavigate()

    console.log('Component render - folders:', folders);
    console.log('Component render - isLoading:', isLoading);

    useEffect(() => {
        const fetchUserOrganizations = async () => {
            try {
                let organizations = [];
                if (auth.isSuperAdmin) {
                    const response = await sendRequest(
                        "/api/organizations",
                        "GET",
                        null,
                        {
                            Authorization: "Bearer " + auth.token,
                        }
                    );
                    organizations = response.data;
                } else {
                    organizations = auth.memberships;
                }

                setUserOrganizations(organizations);
                setShowOrganizationDropdown(
                    auth.isSuperAdmin || organizations.length > 1
                );
                setOrganizationsLoaded(true);
            } catch (err) {
                console.error("Error fetching organizations:", err);
            }
        };

        fetchUserOrganizations();
    }, [auth, sendRequest]);

    useEffect(() => {
        if (organizationsLoaded && userOrganizations.length === 1) {
            setSelectedOrgId(userOrganizations[0].orgId);
        }
    }, [organizationsLoaded, userOrganizations]);

    useEffect(() => {
        console.log('Folders updated:', folders);
    }, [folders]);
    
    const handleCreateFolder = async () => {
      if (!newFolderName.trim()) {
        setError('Folder name is required')
        return
      }

      if (!selectedOrgId) {
        setError('Please select an organization')
        return
      }

      try {
        await createFolder(newFolderName, selectedOrgId);
        setShowModal(false)
        setNewFolderName('')
        setError('')
      } catch (err) {
        setError('Failed to create folder. Please try again.')
        console.error('Error creating folder:', err)
      }
    }

    const formatDate = (timestamp) => {
        if (!timestamp) return "No date available";

        // Handle Firestore Timestamp object with _seconds
        if (timestamp && timestamp._seconds) {
            const date = new Date(timestamp._seconds * 1000);
            const month = date.toLocaleString("en-US", { month: "short" });
            const day = date.getDate();
            const time = date.toLocaleString("en-US", {
                hour: "numeric",
                minute: "2-digit",
                hour12: true,
            });

            return `${month} ${day} at ${time}`;
        }

        // Handle regular Date object
        if (timestamp instanceof Date) {
            const month = timestamp.toLocaleString("en-US", { month: "short" });
            const day = timestamp.getDate();
            const time = timestamp.toLocaleString("en-US", {
                hour: "numeric",
                minute: "2-digit",
                hour12: true,
            });

            return `${month} ${day} at ${time}`;
        }

        return "Invalid Date";
    };

    const handleDeleteFolder = async (e, folderId) => {
        e.stopPropagation(); // Prevent row click
        try {
            await deleteFolder(folderId);
        } catch (err) {
            setError('Failed to delete folder. Please try again.');
        }
    };

    const handleTogglePin = async (e, folderId) => {
        e.stopPropagation(); // Prevent row click
        try {
            await togglePinFolder(folderId);
        } catch (err) {
            setError('Failed to pin/unpin folder. Please try again.');
        }
    };

    const pinnedFolders = folders.filter(folder => folder.isPinned);
    const unpinnedFolders = folders.filter(folder => !folder.isPinned);

    return (
      <div className="p-6 max-w-6xl mx-auto h-screen flex flex-col">
        {showModal && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-8 rounded-lg shadow-lg w-[800px]">
              <h3 className="text-xl font-semibold mb-6">Create New Folder</h3>
              <div className="space-y-6">
                <input
                  type="text"
                  placeholder="Folder name"
                  className={`w-full px-6 py-3 text-lg border rounded-lg ${error ? 'border-red-500' : ''}`}
                  value={newFolderName}
                  onChange={(e) => {
                    setNewFolderName(e.target.value)
                    setError('')
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') handleCreateFolder()
                    if (e.key === 'Escape') setShowModal(false)
                  }}
                />
                {showOrganizationDropdown && (
                  <select
                    className="w-full px-6 py-3 text-lg border rounded-lg"
                    value={selectedOrgId}
                    onChange={(e) => setSelectedOrgId(e.target.value)}
                  >
                    <option value="">Select an organization</option>
                    {userOrganizations.map((org) => (
                      <option key={org.orgId} value={org.orgId}>
                        {org.orgName || org.name}
                      </option>
                    ))}
                  </select>
                )}
                {error && <p className="text-red-500 text-base">{error}</p>}
              </div>
              <div className="flex justify-center space-x-6 mt-8">
                <button
                  className="px-6 py-3 text-lg text-gray-600 hover:bg-gray-100 rounded-lg"
                  onClick={() => {
                    setShowModal(false)
                    setError('')
                    setNewFolderName('')
                  }}
                >
                  Cancel
                </button>
                <button
                  className="px-6 py-3 text-lg bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50"
                  onClick={handleCreateFolder}
                  disabled={!newFolderName.trim() || !selectedOrgId}
                >
                  Create
                </button>
              </div>
            </div>
          </div>
        )}

        <div className="space-y-4 flex-shrink-0">
          <h2 className="text-lg font-semibold">Pinned Folders</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            {isLoading ? (
              // Skeleton loading for pinned folders
              Array(4).fill(0).map((_, index) => (
                <div key={index} className="p-4 bg-white rounded-lg shadow-sm border">
                  <div className="space-y-3">
                    <div className="w-8 h-8 bg-gray-200 rounded animate-pulse" />
                    <div className="h-4 bg-gray-200 rounded w-3/4 animate-pulse" />
                    <div className="h-3 bg-gray-200 rounded w-1/2 animate-pulse" />
                  </div>
                </div>
              ))
            ) : (
              pinnedFolders.map((folder) => (
                <div
                  key={folder.id}
                  className="p-4 bg-white rounded-lg shadow-sm border hover:shadow-md transition-shadow cursor-pointer relative"
                  onClick={(e) => {
                    // Only navigate if not clicking the dropdown
                    if (!e.target.closest('.folder-dropdown')) {
                      navigate(`/folders/${folder.id}`);
                    }
                  }}
                >
                  <div className="absolute top-2 right-2">
                    <div className="relative inline-block text-left folder-dropdown">
                      <button
                        className="p-2 hover:bg-gray-100 rounded-full"
                        onClick={(e) => {
                          e.stopPropagation();
                          const menu = e.currentTarget.nextElementSibling;
                          menu.classList.toggle("hidden");
                        }}
                      >
                        <MoreHorizontal className="w-4 h-4" />
                        <span className="sr-only">Open menu</span>
                      </button>
                      <div 
                        className="hidden absolute right-0 mt-2 w-36 bg-white rounded-md shadow-lg border divide-y z-10"
                        onClick={e => e.stopPropagation()}
                      >
                        <button 
                          className="w-full text-left px-4 py-2 text-sm hover:bg-gray-50"
                          onClick={(e) => handleTogglePin(e, folder.id)}
                        >
                          Unpin
                        </button>
                        <button 
                          className="w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-gray-50"
                          onClick={(e) => handleDeleteFolder(e, folder.id)}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="space-y-3">
                    <Folder className="w-8 h-8 text-blue-600 fill-blue-600" strokeWidth={1} />
                    <h3 className="font-medium">{folder.name}</h3>
                    <p className="text-sm text-gray-500">
                      {formatDate(folder.createdAt)} | {folder.items || 0} Items
                    </p>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>

        <div className="space-y-4 flex-grow flex flex-col min-h-0 mt-8">
          <div className="flex justify-between items-center flex-shrink-0">
            <h2 className="text-lg font-semibold">My Folders</h2>
            <button 
              className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
              onClick={() => setShowModal(true)}
            >
              <Plus className="w-4 h-4 mr-2" />
              Add New
            </button>
          </div>

          <div className="w-full overflow-hidden border rounded-lg flex-grow flex flex-col min-h-0 mt-4">
            <style>
              {`
                .custom-scrollbar::-webkit-scrollbar {
                  width: 8px;
                }
                .custom-scrollbar::-webkit-scrollbar-track {
                  background: transparent;
                }
                .custom-scrollbar::-webkit-scrollbar-thumb {
                  background-color: #E5E7EB;
                  border-radius: 20px;
                  border: 2px solid white;
                }
                .custom-scrollbar::-webkit-scrollbar-thumb:hover {
                  background-color: #D1D5DB;
                }
              `}
            </style>
            <div className="w-full">
              <table className="w-full text-left">
                <thead className="border-b bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-sm font-semibold" style={{ width: '65%' }}>
                      <div className="flex items-center">
                        <span style={{ width: '40%' }}>Name</span>
                        <span style={{ width: '60%', paddingRight: '10%' }} className="text-center">Video Count</span>
                      </div>
                    </th>
                    <th className="px-6 py-3 text-sm font-semibold text-center" style={{ width: '25%' }}>Created</th>
                    <th className="px-6 py-3 text-sm font-semibold" style={{ width: '10%' }}></th>
                  </tr>
                </thead>
              </table>
            </div>
            <div className="flex-grow overflow-y-auto custom-scrollbar">
              <table className="w-full text-left">
                <tbody>
                  {isLoading ? (
                    // Skeleton loading for table rows
                    Array(5).fill(0).map((_, index) => (
                      <tr key={index} className="border-b">
                        <td className="px-6 py-4">
                          <div className="h-4 bg-gray-200 rounded w-72 animate-pulse" />
                        </td>
                        <td className="px-6 py-4 text-right">
                          <div className="h-4 bg-gray-200 rounded w-24 ml-auto animate-pulse" />
                        </td>
                        <td className="px-6 py-4">
                          <div className="h-8 w-8 bg-gray-200 rounded-full ml-auto animate-pulse" />
                        </td>
                      </tr>
                    ))
                  ) : !Array.isArray(unpinnedFolders) || unpinnedFolders.length === 0 ? (
                    <tr>
                      <td colSpan="4" className="px-6 py-4 text-center">No folders found</td>
                    </tr>
                  ) : (
                    unpinnedFolders.map((folder) => (
                      <tr 
                        key={folder.id} 
                        className="hover:bg-gray-50 cursor-pointer border-b"
                        onClick={() => navigate(`/folders/${folder.id}`)}
                      >
                        <td className="px-6 py-4" style={{ width: '65%' }}>
                          <div className="flex items-center">
                            <span style={{ width: '40%' }}>{folder.name}</span>
                            <span style={{ width: '60%', paddingRight: '10%' }} className="text-center text-gray-600">{folder.items || 0}</span>
                          </div>
                        </td>
                        <td className="px-6 py-4 text-center" style={{ width: '25%' }}>{formatDate(folder.createdAt)}</td>
                        <td className="px-6 py-4 text-right" style={{ width: '10%' }}>
                          <div className="relative inline-block text-left">
                            <button
                              className="p-2 hover:bg-gray-100 rounded-full"
                              onClick={(e) => {
                                e.stopPropagation();
                                const menu = e.currentTarget.nextElementSibling;
                                menu.classList.toggle("hidden");
                              }}
                            >
                              <MoreVertical className="w-4 h-4" />
                              <span className="sr-only">Open menu</span>
                            </button>
                            <div 
                              className="hidden absolute right-0 mt-2 w-36 bg-white rounded-md shadow-lg border divide-y z-10"
                              onClick={e => e.stopPropagation()}
                            >
                              <button 
                                className="w-full text-left px-4 py-2 text-sm hover:bg-gray-50"
                                onClick={(e) => handleTogglePin(e, folder.id)}
                              >
                                {folder.isPinned ? 'Unpin' : 'Pin'}
                              </button>
                              <button 
                                className="w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-gray-50"
                                onClick={(e) => handleDeleteFolder(e, folder.id)}
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
}