import { FaShareAlt, FaEnvelope, FaFacebook, FaTwitter, FaLinkedin } from "react-icons/fa";
import { Dropdown, ButtonGroup } from "react-bootstrap";

const getSocialMediaLinks = (videoUrl, title = "Check this out!") => ({
    email: `mailto:?subject=${encodeURIComponent(title)}&body=${encodeURIComponent(videoUrl)}`,
    facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(videoUrl)}`,
    twitter: `https://twitter.com/intent/tweet?url=${encodeURIComponent(videoUrl)}&text=${encodeURIComponent(title)}`,
    linkedin: `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(videoUrl)}`,
});

const ShareButton = ({ videoUrl, title }) => {
    const shareLinks = getSocialMediaLinks(videoUrl, title);
    
    const handleShare = async (videoUrl, title = "Ratava video message") => {
        if (navigator.share) {
            try {
                await navigator.share({
                    title: title,
                    text: "Check out this Ratava video!",
                    url: videoUrl,
                });
                console.log("Shared successfully");
            } catch (error) {
                console.error("Error sharing:", error);
            }
        } else {
            console.log("Web Share API not supported. Showing fallback options.");
        }
    };

    return (
        <Dropdown as={ButtonGroup} className="share-dropdown">
            <Dropdown.Toggle variant="primary" id="dropdown-basic" style={{ padding: "4px 8px", fontSize: "14px" }}>
                <FaShareAlt /> Share
            </Dropdown.Toggle>

            <Dropdown.Menu className="share-menu">
                {navigator.share && (
                    <Dropdown.Item onClick={() => handleShare(videoUrl, title)} className="share-item">
                        <span className="share-icon">📱</span>Share via Apps (Mobile)
                    </Dropdown.Item>
                )}
                <Dropdown.Item href={shareLinks.email} target="_blank" className="share-item">
                    <FaEnvelope className="share-icon"/> Email
                </Dropdown.Item>
                <Dropdown.Item href={shareLinks.facebook} target="_blank" className="share-item">
                    <FaFacebook className="share-icon"/> Facebook
                </Dropdown.Item>
                <Dropdown.Item href={shareLinks.twitter} target="_blank" className="share-item">
                    <FaTwitter className="share-icon"/> Twitter
                </Dropdown.Item>
                <Dropdown.Item href={shareLinks.linkedin} target="_blank" className="share-item">
                    <FaLinkedin className="share-icon"/> LinkedIn
                </Dropdown.Item>
                <Dropdown.Item onClick={() => navigator.clipboard.writeText(videoUrl)}>
                <span className="share-icon">📋</span>Copy Link
                </Dropdown.Item>
            </Dropdown.Menu>

            <style>{`
                .share-dropdown .btn {
                    padding: 4px 8px;
                    font-size: 12px;
                }

                .share-menu {
                    padding: 5px 8px;
                }

                .share-item {
                    display: flex;
                    align-items: center;
                    padding: 8px 16px;
                    font-size: 14px;
                }

                .share-icon {
                    margin-right: 8px;
                    font-size: 16px;
                }
            `}</style>

        </Dropdown>
    );
};

export default ShareButton;
