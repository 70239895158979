import React, { useState, useEffect, useContext, useCallback } from "react";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import {
	Button,
	ListGroup,
	Spinner,
	ToggleButtonGroup,
	ToggleButton,
	Offcanvas,
	Toast,
	Form,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ToastError from "../../shared/elements/ToastError";
import VideoCard from "./VideoCard";
import VideoModal from "./VideoModal";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {
	FaList,
	FaTh,
	FaDownload
} from "react-icons/fa";

import ShareButton from "./ShareButton";

const VideoList = () => {
	const authContext = useContext(AuthContext);
	const { isLoading, error, sendRequest, clearError } = useHttpClient();
	const [videos, setVideos] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const [selectedVideo, setSelectedVideo] = useState(null);
	const [view, setView] = useState("cards");
	const [showToast, setShowToast] = useState(false);
	const [showOffcanvas, setShowOffcanvas] = useState(false);
	const handleClose = () => setShowOffcanvas(false);
	const handleShow = () => setShowOffcanvas(true);
	const [searchTerm, setSearchTerm] = useState("");

	const renderStatus = (status, videoUrl, publicShared) => {
		let statusElement;

		if (status === "pending") {
			statusElement = (
				<Spinner animation="border" size="sm" variant="warning" />
			);
		} else if (status === "processing") {
			statusElement = (
				<Spinner animation="border" size="sm" variant="success" />
			);
		} else if (status === "completed") {
			statusElement = (
				<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
					<OverlayTrigger
						placement="top"
						delay={{ show: 250, hide: 400 }}
						overlay={
							<Tooltip>{status === "completed" ? "Download" : status}</Tooltip>
						}
					>
						<Button
							variant="link"
							style={{ padding: "5px", borderRadius: "50%" }}
							href={videoUrl}
							target="_blank"
							rel="noopener noreferrer"
							download
						>
							<FaDownload />
						</Button>
					</OverlayTrigger>
					{publicShared && (
						<ShareButton 
							videoUrl={`${window.location.origin}/video?id=${selectedVideo.id}`}
							title={selectedVideo.title}
						/>
					)}
				</div>
			);
		} else if (status === "failed") {
			statusElement = (
				<span
					style={{
						display: "inline-block",
						width: "10px",
						height: "10px",
						backgroundColor: "red",
						borderRadius: "50%",
					}}
				></span>
			);
		}

		return (
			<div style={{ display: "inline-block" }}>{statusElement}</div>
		);
	};

	const navigate = useNavigate();

	const handleDeleteVideo = async () => {
		if (!selectedVideo) return;
		try {
			await sendRequest(
				`/api/videos/${selectedVideo.id}`,
				"DELETE",
				JSON.stringify({ orgName: selectedVideo.orgName }),
				{
					Authorization: "Bearer " + authContext.token,
					"Content-Type": "application/json",
				}
			);
			setShowModal(false);
			fetchVideos(); // Refresh the video list after finalization
		} catch (err) {
			console.error("Error deleting video:", err);
		}
	};

	const fetchVideos = useCallback(async () => {
		try {
			const response = await sendRequest("/api/videos", "GET", null, {
				Authorization: "Bearer " + authContext.token,
			});
			const responseData = response.data;
			if (Array.isArray(responseData)) {
				setVideos(responseData);
			} else {
				setVideos([]);
			}
		} catch (err) {
			console.error("Error fetching videos:", err);
		}
	}, [sendRequest, authContext.token]);

	useEffect(() => {
		fetchVideos();
	}, [fetchVideos]);

	const handleShowVideo = async (video) => {
		try {
			const response = await sendRequest(
				`/api/videos/${video.id}/details`,
				"GET",
				null,
				{
					Authorization: "Bearer " + authContext.token,
				}
			);
			setSelectedVideo(response.data);
			setShowModal(true);
		} catch (err) {
			console.error("Error fetching video details:", err);
		}
	};

	/*const handleEditVideo = () => {
		if (selectedVideo) {
			navigate(`/videos/edit/${selectedVideo.id}`);
		}
	};*/

	const handleCloseModal = () => {
		setShowModal(false);
		setSelectedVideo(null);
	};

	const handleViewChange = (val) => setView(val);

	const filteredVideos = videos.filter((video) =>
		video.title.toLowerCase().includes(searchTerm.toLowerCase())
	);

	return (
		<div data-tour-target="studio-content">
			<ToastError show={!!error} message={error} onClose={clearError} />
			<Button
				variant="primary"
				onClick={() => navigate("/videos/new")}
				style={{ marginBottom: "20px", marginRight: "20px" }}
			>
				Add Video
			</Button>
			<ToggleButtonGroup
				type="radio"
				name="view"
				value={view}
				onChange={handleViewChange}
				style={{ marginBottom: "20px" }}
			>
				<ToggleButton id="tbg-btn-1" value={"list"}>
					<FaList />
				</ToggleButton>
				<ToggleButton id="tbg-btn-2" value={"cards"}>
					<FaTh />
				</ToggleButton>
			</ToggleButtonGroup>
			<Button
				variant="light"
				style={{ marginLeft: "20px", marginBottom: "20px" }}
				onClick={handleShow}
			>
				Important Note!
			</Button>
			<Form.Control
				className="hover-border"
				type="text"
				placeholder="Search by title..."
				style={{ marginBottom: "20px", width: "300px" }}
				value={searchTerm}
				onChange={(e) => setSearchTerm(e.target.value)}
			/>
			{isLoading && <Spinner animation="border" />}
			{view === "list" ? (
				<ListGroup>
					{filteredVideos.length === 0 && (
						<ListGroup.Item>No videos found.</ListGroup.Item>
					)}
					{filteredVideos.map((video) => (
						<ListGroup.Item
							key={video.id}
							onClick={() => handleShowVideo(video)}
						>
							{video.title}
						</ListGroup.Item>
					))}
				</ListGroup>
			) : (
				<div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
					{filteredVideos.length === 0 && <p>No videos found.</p>}
					{filteredVideos.map((video) => (
						<VideoCard
							key={video.id}
							video={video}
							onShowVideo={handleShowVideo}
						/>
					))}
				</div>
			)}
			{selectedVideo && (
				<VideoModal
					show={showModal}
					onHide={handleCloseModal}
					video={selectedVideo}
					onDelete={handleDeleteVideo}
					renderStatus={renderStatus}
				/>
			)}
			<Offcanvas show={showOffcanvas} onHide={handleClose} placement={"end"}>
				<Offcanvas.Header closeButton>
					<Offcanvas.Title>
						<span class="icon">👋</span>Hello!
					</Offcanvas.Title>
				</Offcanvas.Header>
				<Offcanvas.Body>
					<p>
						Please note that generating videos will take some time. Make sure to
						refresh the page to see the status of your video.
					</p>
					<p>
						Due to how videos are made with the API, we recommend them for
						internal use only. If you would like to make a video for commercial
						purposes, like advertising or social media, contact us!
					</p>
					<p>
						<strong>
							Please contact Max or Kaleb to to create a professional video for
							you!
						</strong>
					</p>
					<p>
						<ul>
							<strong>Max: </strong>
							<a href="mailto:maximus@ratava.com">maximus@ratava.com</a>
						</ul>
						<ul>
							<strong>Kaleb: </strong>
							<a href="mailto:kaleb@ratava.com">kaleb@ratava.com</a>
						</ul>
					</p>
				</Offcanvas.Body>
			</Offcanvas>
			<Toast
				onClose={() => setShowToast(false)}
				show={showToast}
				delay={6000}
				autohide
				style={{ position: "fixed", top: 20, right: 20, zIndex: 9999 }}
			>
				<Toast.Header>Link copied to clipboard!</Toast.Header>
			</Toast>
		</div>
	);
};

export default VideoList;
