import { useState, useEffect, useCallback, useContext } from "react";
import { useHttpClient } from "./http-hook";
import { AuthContext } from "../context/auth-context";

const useFolders = () => {
	const [folders, setFolders] = useState([]);
	const { isLoading, error, sendRequest, clearError } = useHttpClient();
	const auth = useContext(AuthContext);

	const fetchFolders = useCallback(async () => {
		try {
			console.log("Fetching folders...");
			const response = await sendRequest("/api/folders", "GET", null, {
				Authorization: "Bearer " + auth.token,
			});
			console.log("Response from /api/folders:", response);

			// The http-hook wraps the response in a data property
			const foldersArray = response?.data || [];
			setFolders(foldersArray);
			console.log("Folders updated:", foldersArray);
		} catch (err) {
			console.error("Error fetching folders:", err);
			setFolders([]); // Set empty array on error
		}
	}, [sendRequest, auth.token]);

	useEffect(() => {
		fetchFolders();
	}, [fetchFolders]);

	const createFolder = useCallback(
		async (name, orgId) => {
			try {
				console.log("Creating folder with name:", name, "and orgId:", orgId);
				const response = await sendRequest(
					"/api/folders",
					"POST",
					JSON.stringify({
						name: name.trim(),
						orgId: orgId,
						createdAt: new Date(),
						items: 0,
					}),
					{
						"Content-Type": "application/json",
						Authorization: "Bearer " + auth.token,
					}
				);
				console.log("Create folder response:", response);
				await fetchFolders();
				return true;
			} catch (err) {
				console.error("Error creating folder:", err);
				throw err;
			}
		},
		[sendRequest, auth.token, fetchFolders]
	);

	const deleteFolder = useCallback(
		async (folderId) => {
			try {
				await sendRequest(`/api/folders/${folderId}`, "DELETE", null, {
					Authorization: "Bearer " + auth.token,
				});
				await fetchFolders(); // Refresh the folders list
				return true;
			} catch (err) {
				console.error("Error deleting folder:", err);
				throw err;
			}
		},
		[sendRequest, auth.token, fetchFolders]
	);

	const togglePinFolder = useCallback(
		async (folderId) => {
			try {
				const response = await sendRequest(
					`/api/folders/${folderId}/toggle-pin`,
					"POST",
					null,
					{
						Authorization: "Bearer " + auth.token,
					}
				);
				await fetchFolders(); // Refresh the folders list
				return response.data.isPinned;
			} catch (err) {
				console.error("Error toggling folder pin:", err);
				throw err;
			}
		},
		[sendRequest, auth.token, fetchFolders]
	);

	return {
		folders,
		isLoading,
		error,
		clearError,
		fetchFolders,
		createFolder,
		deleteFolder,
		togglePinFolder,
	};
};

export default useFolders;
