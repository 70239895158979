import { React, useState, useContext } from "react";
import {
	CDBSidebar,
	CDBSidebarContent,
	CDBSidebarFooter,
	CDBSidebarHeader,
	CDBSidebarMenu,
	CDBSidebarMenuItem,
	CDBBadge,
} from "cdbreact";
import { NavLink } from "react-router-dom";

import { Modal } from "react-bootstrap";

import { AuthContext } from "../../shared/context/auth-context";

import VersionInfo from "./VersionInfo";

const Sidebar = () => {
	const { isSuperAdmin, orgRole } = useContext(AuthContext);
	const [showHelpModal, setShowHelpModal] = useState(false);
	//const { isSuperAdmin, orgRole, orgName } = useContext(AuthContext);
	/*const dashboardTitle = isSuperAdmin
		? "Super Admin Dashboard"
		: orgName
		? `${orgName} Dashboard`
		: "Dashboard";*/

	const getActiveClassName = ({ isActive }) =>
		isActive ? "activeClicked" : "";

	const menuItems = [
		{ to: "/", icon: "columns", label: "Dashboard" },
		{
			to: "/videos",
			icon: "photo-video",
			label: "Videos",
			tourTarget: "studio",
		},
		{
			to: "/scripts",
			icon: "file",
			label: "Scripts",
			tourTarget: "script-writer",
		},
		{
			to: "/audios",
			icon: "music",
			label: "Audios",
		},
		{
			to: "/folders",
			icon: "folder",
			label: "Folders",
		},
		//{ to: "/avatarlist", icon: "users", label: "Avatar List" },
		{ to: "/settings", icon: "cog", label: "Settings", tourTarget: "settings" },
		//{ to: "/clients", icon: "user", label: "Clients" },
		//{ to: "/campaigns", icon: "users", label: "Campaigns" },
	];

	if (isSuperAdmin) {
		menuItems.push(
			{ to: "/session", icon: "user-astronaut", label: "Live Session" },
			{
				to: "/admin",
				icon: "lock",
				label: "Admin Settings",
				suffix: (
					<CDBBadge color="secondary" size="small" borderType="pill">
						Admin
					</CDBBadge>
				),
			},
			{
				to: "/add-avatar",
				icon: "user-plus",
				label: "Add Avatar",
				suffix: (
					<CDBBadge color="secondary" size="small" borderType="pill">
						Admin
					</CDBBadge>
				),
			},
			{
				to: "/user-list",
				icon: "users",
				label: "User List",
				suffix: (
					<CDBBadge color="secondary" size="small" borderType="pill">
						Admin
					</CDBBadge>
				),
			}
		);
	} else if (orgRole === "orgAdmin") {
		menuItems.push(
			//{ to: "/session", icon: "user-astronaut", label: "Live Session" },
			{
				to: "/organizations",
				icon: "lock",
				label: "Org Settings",
				suffix: (
					<CDBBadge color="secondary" size="small" borderType="pill">
						Admin
					</CDBBadge>
				),
			}
		);
	}

	return (
		<div
			data-tour-target="sidebar"
			style={{ display: "flex", height: "100vh", overflow: "auto" }}
		>
			<CDBSidebar textColor="#fff" backgroundColor="#1c004b">
				<CDBSidebarHeader prefix={<i className="fa fa-bars fa-large"></i>}>
					<div
						className="container"
						style={{ display: "flex", alignItems: "center" }}
					>
						<img src="/logo-white.png" alt="logo" style={{ width: "100%" }} />
					</div>

					{/*<a
						href="/"
						className="text-decoration-none"
						style={{ color: "inherit" }}
					>
						{dashboardTitle}
					</a>*/}
				</CDBSidebarHeader>

				<CDBSidebarContent className="sidebar-content">
					<CDBSidebarMenu>
						{menuItems.map((item, index) => (
							<NavLink
								key={index}
								to={item.to}
								className={getActiveClassName}
								data-tour-target={item.tourTarget}
							>
								<CDBSidebarMenuItem icon={item.icon} suffix={item.suffix}>
									{item.label}
								</CDBSidebarMenuItem>
							</NavLink>
						))}
					</CDBSidebarMenu>
				</CDBSidebarContent>

				<CDBSidebarFooter style={{ textAlign: "center" }}>
					<div
						style={{
							padding: "20px 5px",
							cursor: "pointer",
						}}
						onClick={() => setShowHelpModal(true)}
					>
						Need help?
					</div>
					<VersionInfo />
				</CDBSidebarFooter>
			</CDBSidebar>
			<HelpModal show={showHelpModal} onHide={() => setShowHelpModal(false)} />
		</div>
	);
};

const HelpModal = ({ show, onHide }) => {
	return (
		<Modal show={show} onHide={onHide} centered size="lg">
			<Modal.Header closeButton>
				<Modal.Title>Tutorial</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div style={{ textAlign: "center" }}>
					<div
						style={{
							position: "relative",
							paddingBottom: "56.25%", // 16:9 aspect ratio (9/16 = 0.5625 * 100%)
							height: 0,
							overflow: "hidden",
							maxWidth: "100%",
							margin: "0 auto",
						}}
					>
						<iframe
							src="https://drive.google.com/file/d/1LlNLlkbfiucnlaLYLl6UrwVpLCwH3RuS/preview"
							allow="encrypted-media"
							allowFullScreen
							title="Tutorial Video"
							style={{
								border: "none",
								position: "absolute",
								top: 0,
								left: 0,
								width: "100%",
								height: "100%",
							}}
						></iframe>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default Sidebar;
