import { useState, useRef } from "react";
import MicRecorder from "mic-recorder-to-mp3";

export default function useAudioRecorder() {
  const [isRecording, setIsRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const [audioURL, setAudioURL] = useState(null);

  const recorderRef = useRef(new MicRecorder({ bitRate: 128 }));

  const startRecording = async () => {
    try {
      await recorderRef.current.start();
      setIsRecording(true);
    } catch (err) {
      console.error("Failed to start recording:", err);
    }
  };

  const stopRecording = async () => {
    try {
      const [, blob] = await recorderRef.current.stop().getMp3();
      const url = URL.createObjectURL(blob);
      setAudioBlob(blob);
      setAudioURL(url);
      setIsRecording(false);
    } catch (err) {
      console.error("Failed to stop recording:", err);
    }
  };

  const resetRecording = () => {
    setAudioBlob(null);
    setAudioURL(null);
    setIsRecording(false);
  };

  return {
    isRecording,
    audioBlob,
    audioURL,
    startRecording,
    stopRecording,
    resetRecording,
  };
}
